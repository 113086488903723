<template>
  <div>
    <Greeting
      :name="`${user.personalData.firstName} ${user.personalData.lastName}`"
    />
    <div v-if="loading">
      <v-skeleton-loader
        type="heading"
        :width="300"
        :height="50"
        class="rounded-lg"
      ></v-skeleton-loader>
      <v-row v-for="index in 2" :key="index">
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="card"
            :height="500"
            class="rounded-lg card-loader"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="card"
            :height="500"
            class="rounded-lg card-loader"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <AccountBalance
        :title="$t('views.Portfolio')"
        :totalBalance="totalBalance"
      />
      <v-row v-if="portfolio.length > 0" class="mt-5">
        <v-col
          v-for="(type, index) in assetTypes"
          :key="index"
          cols="12"
          md="8"
          lg="6"
        >
          <TransactionCard
            :items="groupedItems[type]"
            :type="type"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" lg="6">
          <Liquidity
            v-if="totalBalance > 0"
            :totalBalance="balance.spendableCashBalance"
            class="mt-5"
          />
        </v-col>
      </v-row>
      <MarketplaceTeaser
        class="mt-8"
        v-if="portfolio.length === 0"
      />
      <TitleWithSubtitle
        class="my-5"
        :title="$t('components.shared.comingSoon.title')"
        :subtitle="$t('components.shared.comingSoon.desc')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import groupBy from 'lodash.groupby';

export default {
  name: 'Portfolio',
  components: {
    Greeting: lazyLoad('components/Shared/Greeting'),
    AccountBalance: lazyLoad('components/Shared/Cards/AccountBalance'),
    TransactionCard: lazyLoad('components/Shared/Cards/TransactionCard'),
    Liquidity: lazyLoad('components/Shared/Cards/Liquidity'),
    MarketplaceTeaser: lazyLoad('components/Shared/Cards/MarketplaceTeaser'),
    TitleWithSubtitle: lazyLoad('components/Shared/Cards/TitleWithSubtitle'),
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters('user', [
      'user',
      'balance',
      'portfolio',
    ]),
    totalBalance() {
      return this.balance.spendableCashBalance + this.balance.totalSubscriptionAmount;
    },
    groupedItems() {
      return groupBy(this.portfolio, (item) => item.assetType);
    },
    assetTypes() {
      return Object.keys(this.groupedItems);
    },
  },
  async created() {
    if (this.balance.paymentId !== '') {
      this.loading = false;
    }
    try {
      await Promise.all([
        // get Balance
        this.getBalance(),
        // get portfolio details
        this.getPortfolio(),
        // get assets
        this.fetch(),
      ]);
    } catch (error) {
      this.$notify.error(error);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('user', [
      'getBalance',
      'getPortfolio',
    ]),
    ...mapActions('assets', [
      'fetch',
    ]),
  },
};
</script>

<style lang="scss">
.card-loader {
  .v-skeleton-loader__image.v-skeleton-loader__bone {
    height: 400px;
  }
}
</style>
